import { DatePipe, NgClass, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { DATE_ISO_FORMAT } from '../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { GTM_EVENTS, LOCAL_STORAGE_CONSTANTS, WIDGET_CONSTANTS, WINDOW_LISTENER_CONSTANTS } from '../../../constants/widget-constants';
import { CustomFieldOptions, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { AppointmentReservationBody } from '../../../db-models/appointment-reservation.model';
import { AppointmentServiceDao } from '../../../db-models/appointment-service-dao';
import { CouponDbModel } from '../../../db-models/coupon-db.model';
import { AppointmentsSlotsDao } from '../../../db-models/free-appointment-dao';
import { PayPalOrderDetailModel, PayPalSettingsDbModel } from '../../../db-models/partner-setting.model';
import { StoresDao } from '../../../db-models/stores-dao';
import { WidgetAppointmentGroupModel } from '../../../db-models/widget-appointment-group.dao';
import { AppointmentAnalyticsDao, CustomBookingMessageTemplate, PartnerDao, WidgetBookingInfoDao, WorkerDao } from '../../../db-models/widget-conf-dao';
import { AppointmentBookItem, CartItem } from '../../../models/cart.model';
import { GlobalObjects } from '../../../models/global';
import { SaferpayPaymentDetailsDbModel } from '../../../models/saferpay.model';
import { AppointmentState, UserState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomerPrefillDataModel } from '../../../models/widget-conf';
import { AppointmentCartService } from '../../../services/appointment-cart.service';
import { AppointmentService } from '../../../services/appointment.service';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { PaymentService } from '../../../services/payment.service';
import { UtilService } from '../../../services/util.service';
import { WidgetUtilService } from '../../../services/widget-util.service';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { FinalpageComponent } from '../../../shared/components/finalpage/finalpage.component';
import { NewAlertComponent } from '../../../shared/components/new-alert/new-alert.component';
import { CalioCurrencyPipe } from '../../../shared/pipes/calio-currency.pipe';
import { CalioMeetingTemplatePipe } from '../../../shared/pipes/calio-meeting-template.pipe';
import { DateUtcPipe } from '../../../shared/pipes/date_utc.pipe';
import { HourReplacePipe } from '../../../shared/pipes/hour-replace.pipe';
import { MinuteReplacePipe } from '../../../shared/pipes/minute-replace.pipe';
import { ReplaceCommaPipe } from '../../../shared/pipes/replace-comma.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { CwPaymentMethodsComponent } from '../../common/cw-payment-methods/cw-payment-methods.component';
import { NewCalendarPickerComponent } from '../../common/new-calendar-picker/new-calendar-picker.component';
import { CompactPersonalFormComponent } from './compact-personal-form/compact-personal-form.component';
import { CompactSlotsComponent } from './compact-slots/compact-slots.component';

const component = [NewCalendarPickerComponent, CompactSlotsComponent, CompactPersonalFormComponent, ButtonComponent, AlertComponent, CwPaymentMethodsComponent, FinalpageComponent, NewAlertComponent,];
const module = [FormsModule, FontAwesomeModule, TranslateModule,];
const pipe = [TranslationPipe, TrustHtmlPipe, CalioCurrencyPipe, CalioMeetingTemplatePipe, DateUtcPipe, ReplaceCommaPipe, MinuteReplacePipe, DatePipe, HourReplacePipe, TitleCasePipe];
const ngModule = [NgTemplateOutlet, NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem]

@Component({
  selector: 'app-compact-widget',
  templateUrl: './compact-widget.component.html',
  styleUrls: ['./compact-widget.component.scss'],
  standalone: true,
  imports: [...component, ...module, ...pipe, ...ngModule],
})
export class CompactWidgetComponent implements OnInit, OnDestroy {

  @Input() partner: PartnerDao;
  @Input() worker: WorkerDao;
  @Input() selectedAppointmentServiceIds: string[] = [];
  @Input() widgetColorConf: WidgetColorConf;
  @Input() calendarPreselectedDate: string;
  @Input() dateContext: string;
  @Input() dateContextStart: string;
  @Input() dateContextEnd: string;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() selectedJsonCustomerDetail: CustomerPrefillDataModel;
  @Input() selectedStoreId: number;
  @Input() langSwitcher = true;
  @Input() globals: GlobalObjects;
  @Input() showSubscriptionError = false;
  @Input() customer_notification_preference: string;
  @Input() bookerWorkerId: number;
  @Input() utmSource: string;
  @Input() utmMedium: string;
  @Input() utmCampaign: string;
  @Input() utmContent: string;
  @Input() utmTerm: string;
  @Input() isBookingDisabled = false;
  @Input() isFrame = false;
  @Input() widgetConf: any;
  @Input() lang: string;
  @Input() debug: string;
  @Input() token: string;
  @Input() widgetBookingInfo: WidgetBookingInfoDao;
  @Input() meeting_type_id: string;

  @Output() bookingSuccessEvent = new EventEmitter<any>();

  @ViewChild('cbcwWidgetWrapper') cbcwWidgetWrapperRef: ElementRef;
  @ViewChild('appointmentForm') public appointmentForm: NgForm;

  private cartService = inject(AppointmentCartService);
  private formService = inject(FormsService);
  private customEventService = inject(CustomEventService);
  private utilService = inject(UtilService);
  private appointmentService = inject(AppointmentService);
  private widgetUtilService = inject(WidgetUtilService);
  private partnerService = inject(PartnerService);
  private translateService = inject(TranslateService);
  private localStorageService = inject(LocalStorageService);
  private helperService = inject(HelperService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private paymentService = inject(PaymentService);
  private elementRef = inject(ElementRef);

  readonly logoBaseUrl = environment.logoBaseUrl;
  readonly workerAvatarUrl = environment.workerAvatarUrl;
  readonly baseUrl = environment.baseUrl;
  readonly footerLogoUrl = environment.footerLogoUrl;
  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly gtmEvents = GTM_EVENTS;
  readonly deployUrl = environment.deployUrl;
  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly templateContent = TRANSLATION_TEMPLATES;

  private CART_LS_NAME: string;
  private STATE_LS_NAME: string;

  USER_LS_NAME: string;
  CUSTOMFIELDS_LS_NAME: string;
  alive: boolean;
  booked = false;
  service: AppointmentServiceDao;
  store: StoresDao
  noWorkerFound = false;
  noServiceFound = false;
  loadingService = true;
  cartSupported = false;
  viewDate: Date;
  now = new Date();
  disablePrev = true;
  monthChanged = false;
  lastCalendarMonth: number;
  excludeWeekDays: number[] = [];
  exceptionDays: Date[];
  maxCalendarAvailabilityDate: Date;
  calendarLoaded = false;
  calendarDate = false;
  days: CalendarMonthViewDay[] = [];
  showBookableDateNotAvailableIssue = false;
  automaticBookableDateProcessIsEnabled = false;
  automationBookingDatesLimitReached = false;
  oldSelectCalendarDate: CalendarMonthViewDay = null;
  freeAppointments: {
    dayPeriod: string;
    appointments: AppointmentsSlotsDao[];
    showDayPeriodLabel?: boolean;
    widgetGroup: WidgetAppointmentGroupModel;
  };
  freeAppointmentsCount = 0;
  noAppointments = false;
  showAppointmentSlotGhostElement = true;
  appointmentState: AppointmentState = {
    customerProfileId: null,
    store: null,
    categories: [],
    services: [],
    relatedServices: [],
    worker: null,
    date: null,
    termins: null,
    day: null,
    default_worker_id: null,
    leadGeneratorPostcode: null,
    default_worker: null,
  };
  userState: UserState = {
    title: null,
    forename: null,
    lastName: null,
    gender: null,
    eMail: null,
    phone: null,
    comment: null,
    customerId: null,
    customerUuid: null,
    street: null,
    zip: null,
    city: null,
    country: null,
    country_id: null,
    customer_notification_preference: null,
    meeting_type_id: null,
    company: null,
    internal_comment: null,
    captcha_challenge: null
  };
  cart: CartItem[] = [];
  calendarSlotsCountList: { date: Date; slotsCount: number }[] = [];
  widgetGroups: WidgetAppointmentGroupModel[] = [];
  showAvailableAppointments = false;
  widgetTemplates: CustomBookingMessageTemplate[] = [];
  downloadBookingDetailTemplate: CustomBookingMessageTemplate;
  manageAppointmentNowTemplate: CustomBookingMessageTemplate;
  bookButtonTemplate: CustomBookingMessageTemplate;
  successMessage: string;
  courtesyForm: string;
  leadTime: number;
  customFieldValues: {
    [key: number]: | {
      type: string;
      value: any;
      option_values: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    } | any;
  } = {};
  tempCustomFields: CustomFieldsDao[] = [];
  appointmentCustomFields: CustomFieldsDao[] = [];
  childCustomFieldsLoadedSubscription: Subscription;
  removeCustomFieldsSubscription: Subscription;
  isCustomFieldsLoaded = false;
  supportedWidgetLanguages: string[];
  currentView = '';
  customerSelected: object = null;
  hideBookingButton = false;
  disableBookingButton = false;
  bookingErrorMessage: string;
  stripePaymentMethodId: string;
  coupon: CouponDbModel;
  isInvalidCoupon = false;
  paymentType: string;
  paypalOrderDetail: PayPalOrderDetailModel;
  saferpayPaymentDetails: SaferpayPaymentDetailsDbModel;
  successFullBookings: {
    landingPageLink: string;
    bookingUUID: string;
    selectedStore: StoresDao;
  }[] = [];
  finalPage: boolean;
  finalPageError: string;
  priceAfterCouponReduction: number;
  backButtonDisabled = false;
  isServiceOnlinePaymentEnabled = 0;
  isStripeEnabled = false;
  isOnlinePaymentMandatory = false;
  isBexioEnabled = false;
  isStoreEnabled = false;
  isPaypalEnabled = false;
  isSaferpayEnabled = false;
  paypalSetting: PayPalSettingsDbModel;
  finalCouponDiscount = 0;
  totalCartPrice = 0;
  showSaferpayButtonLink = false;
  couponDisableConfirmButton = false;
  showFullscreenOverlay = false;
  availableSlotsArray: AppointmentsSlotsDao[] = [];

  constructor() {
    this.viewDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastCalendarMonth = this.viewDate.getMonth() + 1;
    this.updateCalendarPrevState(this.viewDate);
    this.alive = true;
    this.translateService.onLangChange.subscribe(language => {
      this.lang = language.lang;
      this.createSuccessMessage();
    });
  }

  ngOnInit(): void {
    this.currentView = this.appointmentConstant.APPOINTMENT;

    if (this.worker === undefined || !this.worker) {
      this.noWorkerFound = true;
    }
    if (this.selectedAppointmentServiceIds === undefined || this.selectedAppointmentServiceIds?.length === 0) {
      this.noServiceFound = true;
    }

    if (this.worker) {
      this.getService();
      this.getWidgetTemplates();
      this.setLocalstorageNames();
      this.initFormState();

      this.supportedWidgetLanguages = (this.partner?.supported_widget_languages ? this.partner.supported_widget_languages : []);
      this.cartSupported = this.widgetUtilService.getWidgetConf()?.cartSupported;
      this.courtesyForm = this.widgetUtilService.getWidgetConf()?.courtesyForm;
      this.leadTime = this.widgetUtilService.getWidgetConf()?.appointmentLeadTime;
      this.maxCalendarAvailabilityDate = moment().add(this.partner.future_booking_threshold_appointments, 'days').toDate();

      // Setup appointmentState object
      this.appointmentState.store = this.worker.store_id;
      this.appointmentState.worker = this.worker.id;
      this.appointmentState.default_worker_id = this.worker.id;

      this.childCustomFieldsLoadedSubscription = this.customEventService.childCustomFieldsLoadedEvent.subscribe(customFields => {
        this.tempCustomFields = this.tempCustomFields.concat(customFields);
      });

      this.removeCustomFieldsSubscription = this.customEventService.removeChildCustomFieldsEvent.subscribe(ids => {
        this.tempCustomFields = this.tempCustomFields.filter(item => ids.indexOf(item.id) === -1);
      });
    } else {
      this.noWorkerFound = true;
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private getService(): void {
    const payload = {
      store_id: this.worker.store_id,
      worker_id: this.worker.id,
    };
    this.formService.getAppointmentServices(payload).pipe(
      takeWhile(() => this.alive)
    ).subscribe({
      next: (services) => {
        this.loadingService = false;
        if (services?.length > 0) {
          const foundService = services.find(
            service => service.is_internal !== 1 && service.id === Number(this.selectedAppointmentServiceIds[0])
          );

          if (foundService) {
            this.service = foundService;

            // Setting store of current worker
            const foundWoker = this.service.services_workers.find(worker => worker.worker_id === this.worker.id);
            if (foundWoker) {
              this.store = foundWoker.worker.store;
              if (this.selectedStoreId !== this.store.id) {
                LoggerService.warn('Passed store_id', this.selectedStoreId);
                LoggerService.warn('Worker store_id', this.worker.store_id);
                LoggerService.warn('Passed store_id is not matching with worker_id so overwritting it with valid store_id of worker.');
                this.selectedStoreId = this.worker.store_id;
              }
            }

            this.appointmentState.services = [this.service.id];
            this.appointmentState.categories = [this.service.appointment_service_category.id];
          } else {
            LoggerService.warn('Worker do not have associated service.', this.worker.id, this.selectedAppointmentServiceIds);
            this.noServiceFound = true;
          }
        } else {
          this.noServiceFound = true;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private getWidgetTemplates(): void {
    this.partnerService.getWidgetTemplates().pipe(
      takeWhile(() => this.alive)
    ).subscribe({
      next: (templates: CustomBookingMessageTemplate[]) => {
        this.widgetTemplates = templates;
        this.setupInitialTemplates();
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private setupInitialTemplates(): void {
    // const identifiers = {
    //   WIDGET_NEXT: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NEXT,
    //   WIDGET_BOOK_BUTTON: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_BOOK_BUTTON,
    //   WIDGET_DATE_LABEL: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_DATE_LABEL,
    //   WIDGET_NO_BOOKABLE_DATES_LABEL: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NO_BOOKABLE_DATES_LABEL,
    //   WIDGET_YES_SEARCH_BUTTON_LABEL: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_YES_SEARCH_BUTTON_LABEL,
    //   WIDGET_BOOKING_INFO_APPOINTMENT: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_BOOKING_INFO_APPOINTMENT,
    //   WIDGET_MANAGE_BOOKING_LABEL: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_MANAGE_BOOKING_LABEL,
    //   WIDGET_CHOOSE_DATE_HINT: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_CHOOSE_DATE_HINT,
    //   WIDGET_NO_POSSIBLE_APPOINTMENTS_HINT: WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_NO_POSSIBLE_APPOINTMENTS_HINT,
    //   PERSONAL_DATA_CARD_TITLE: WIDGET_CONSTANTS.WIDGET_LABELS.PERSONAL_DATA_CARD_TITLE
    // };
    // this.widgetTemplates = this.widgetTemplates.filter(template => identifiers[template.identifier.toUpperCase()]);
    this.widgetTemplates.forEach(template => {
      template.is_multi_language = 1;
    });

    this.bookButtonTemplate = this.widgetTemplates.find(
      template => template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_BOOK_BUTTON
    );
    this.downloadBookingDetailTemplate = this.widgetTemplates.find(
      template => template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_DOWNLOAD_APPOINTMENT_ICS_FILE
    );
    this.manageAppointmentNowTemplate = this.widgetTemplates.find(
      template => template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_MANAGE_BOOKING_LABEL
    );

    this.createSuccessMessage();
  }

  private createSuccessMessage(): void {
    const successTemplate = this.widgetTemplates.find(
      template => template.identifier === this.widgetConstants.WIDGET_LABELS.WIDGET_BOOKING_INFO_APPOINTMENT
    );
    this.successMessage = this.utilService.getBookingInfoMessage(successTemplate, this.lang);

    if (!this.successMessage) {
      const translationConstant = (this.courtesyForm === 'Du' ? 'appointments.finalpage.successMessageDu' : 'appointments.finalpage.successMessageSie');
      this.translateService.get(translationConstant, { leadTime: this.leadTime }).subscribe({
        next: (res) => {
          this.successMessage = res;
          this.customEventService.finalSuccessMessageChangedEvent.emit(this.successMessage);
        }
      });
    } else {
      this.customEventService.finalSuccessMessageChangedEvent.emit(this.successMessage);
    }
  }

  // Init user and cart state from localstorage values
  private initFormState(): void {
    this.setInitStateLSName();
    this.setInitCartLSName();
    this.setInitUserLSName();
    this.setInitCustomFieldsLSName();
  }

  private setInitStateLSName(): void {
    // Read local storage to set form state
    this.localStorageService.getItem(this.STATE_LS_NAME, (savedFormState: any) => {
      if (savedFormState) {
        if (savedFormState?.customerProfileId) { this.appointmentState.customerProfileId = savedFormState.customerProfileId; }
        if (savedFormState?.store) { this.appointmentState.store = savedFormState.store; }
        if (savedFormState?.services) { this.appointmentState.services = savedFormState.services; }
        if (savedFormState?.worker) { this.appointmentState.worker = savedFormState.worker; }
        if (savedFormState?.date) { this.appointmentState.date = savedFormState.date; }
        if (savedFormState?.termins) { this.appointmentState.termins = savedFormState.termins; }
        if (savedFormState?.day) { this.appointmentState.day = savedFormState.day; }
        if (savedFormState?.default_worker_id) { this.appointmentState.default_worker_id = savedFormState.default_worker_id; }
        if (savedFormState?.leadGeneratorPostcode) { this.appointmentState.leadGeneratorPostcode = savedFormState.leadGeneratorPostcode; }
        if (savedFormState?.default_worker) { this.appointmentState.default_worker = savedFormState.default_worker; }
        if (savedFormState?.storeDefaultWorkerIds) { this.appointmentState.storeDefaultWorkerIds = savedFormState.storeDefaultWorkerIds; }

        if (this.appointmentState.date) {
          // If selected date from localstorage is in past ignore it
          if (this.appointmentState.date < this.now) {
            this.appointmentState.date = null;
          } else {
            // Set calendar default state to preselected month
            this.viewDate = this.appointmentState.date;
            this.lastCalendarMonth = this.viewDate.getMonth() + 1;
            this.updateCalendarPrevState(this.viewDate);
          }
        }
      }
    });
  }

  private setInitCartLSName(): void {
    this.localStorageService.getItem(this.CART_LS_NAME, (savedCartState: any) => {
      if (savedCartState) {
        this.cart = savedCartState;
      }
    });
  }

  private setInitUserLSName(): void {
    this.localStorageService.getItem(this.USER_LS_NAME, (savedUserState: any) => {
      if (savedUserState) {
        LoggerService.log('Saved user state from localstorage', savedUserState);
        this.userState = savedUserState;
      }
    });
  }

  private setInitCustomFieldsLSName(): void {
    this.localStorageService.getItem(this.CUSTOMFIELDS_LS_NAME, (savedCustomFieldsState: any) => {
      if (savedCustomFieldsState) {
        LoggerService.log('Saved customfields state from localstorage', savedCustomFieldsState);
        this.customFieldValues = savedCustomFieldsState;
      }
    });
  }

  // To change state of previous button in calendar
  private updateCalendarPrevState(viewDate: Date): void {
    this.disablePrev = viewDate.getMonth() <= this.now.getMonth() && viewDate.getFullYear() <= this.now.getFullYear();
  }

  // The method called when you go to the calendar page
  loadCalendarWithAvailabilities({ body }: { body: CalendarMonthViewDay[] }) {
    if (this.monthChanged && this.lastCalendarMonth === body[10].date.getMonth()) {
      return false;
    }

    let startMomentObj: moment.Moment;
    let endMomentObj: moment.Moment;
    let dayOfMonth: string;

    this.lastCalendarMonth = body[10].date.getMonth();
    this.exceptionDays = [];

    if (moment(body[10].date).isSame(this.now, 'month')) {
      dayOfMonth = moment(this.now).format(this.dateIsoFormat);
    } else {
      dayOfMonth = moment(body[10].date).startOf('month').format(this.dateIsoFormat);
    }

    if (this.dateContext && this.dateContextStart && this.dateContextEnd) {
      startMomentObj = moment(this.dateContext, this.dateIsoFormat).subtract(this.dateContextStart, 'days');
      endMomentObj = moment(this.dateContext, this.dateIsoFormat).add(this.dateContextEnd, 'days');
      const endOfMonthMomentObj = moment(body[10].date).endOf('month');
      if (endMomentObj.isBefore(endOfMonthMomentObj)) {
        this.customEventService.disableCalendarNextButtonEvent.emit(true);
      }
    }

    this.formService.getDaysWiseFreeSlots(
      this.worker.id,
      this.worker.store_id,
      this.service.id.toString(),
      dayOfMonth,
      false,
      undefined,
      this.debug,
      this.token
    ).pipe(
      takeWhile(() => this.alive)
    ).subscribe({
      next: availableSlots => {
        this.calendarDate = true;
        this.availableSlotsArray = [];
        let bookingDateIsAvailable = true;

        if (availableSlots && !('errors' in availableSlots)) {
          // preparing array that has day wise availabilites
          availableSlots.available_minutes_blocks?.forEach(daysWiseSlots => {
            daysWiseSlots.workers_minutes_blocks?.forEach(workerMinutesBlock => {
              workerMinutesBlock.minutes_blocks.forEach(availabilities => {
                availabilities.appointments_slots?.forEach(slots => {
                  !this.availableSlotsArray[daysWiseSlots.date] && (this.availableSlotsArray[daysWiseSlots.date] = []);
                  this.availableSlotsArray[daysWiseSlots.date].push({
                    start: slots.appointment_start,
                    end: slots.appointment_end,
                    worker: workerMinutesBlock.worker,
                    store: workerMinutesBlock.store,
                    startHour: parseInt(slots.appointment_start.split(' ')[1].split(':')[0], 10),
                    short_start: slots.appointment_short_start
                  });
                });
              });
            });
          });

          bookingDateIsAvailable = (Object.keys(this.availableSlotsArray)?.length > 0);

          body.forEach(date => {
            const formatedDate = this.utilService.dateToStr(date.date);
            let dateDisabled = false;
            (this.days.length < 7) && this.days.push(date);

            if (
              !this.availableSlotsArray[formatedDate] || // date is not present in available dates array
              date.isPast || // if date is in past
              date.date > this.maxCalendarAvailabilityDate // date is bigger than max date
            ) {
              dateDisabled = true;
            }

            if (!dateDisabled && startMomentObj && endMomentObj) {
              if (moment(date.date).isBetween(startMomentObj, endMomentObj, undefined, '[]')) {
                if (!moment(date.date).isSameOrAfter(new Date(), 'day')) {
                  dateDisabled = true;
                }
              } else {
                dateDisabled = true;
              }
            }

            if (!dateDisabled && !this.dateContext && this.calendarPreselectedDate) {
              if ((date.date < moment(this.calendarPreselectedDate).toDate()) || (date.date > moment(this.calendarPreselectedDate).toDate())) {
                dateDisabled = true;
              }
            }

            if (dateDisabled) {
              date.cssClass = 'cal-disabled';
              date.isWeekend = true;
            }

            // date context is present and current date is same then preselect date
            if (!dateDisabled && this.appointmentState.date !== null && date.date.toDateString() === this.appointmentState.date.toDateString()) {
              this.onDateSelect({ day: date });
            }
          });

          if (!bookingDateIsAvailable) {
            this.showBookableDateNotAvailableIssue = true;
            LoggerService.log('[disable_automatic_search]', this.partner.disable_automatic_search);
            if (Number(this.partner.disable_automatic_search) !== 1) {
              this.automaticBookableDateProcessIsEnabled = true;
            }
            if (this.automaticBookableDateProcessIsEnabled) {
              LoggerService.log('[autoLoadBookableDates]', 'auto checking for appointments');
              this.autoLoadBookableDates();
            }
          } else {
            this.showBookableDateNotAvailableIssue = false;
            this.automaticBookableDateProcessIsEnabled = false;

            // reset flags of next and previous calendar button
            LoggerService.log('[Reset flags of next and previous calendar button]');
            this.customEventService.eventCalendarIsNextPressed = undefined;
            this.customEventService.eventCalendarIsPreviousPressed = undefined;
          }

          this.calendarLoaded = true;
        }
      }
    });
  }

  // Auto load next month availabilities when current month's doesn't have a single availability
  private autoLoadBookableDates() {
    LoggerService.log('Auto loading next month availabilities');

    const minDate: Date = moment().startOf('month').toDate();
    const isNextPressed = this.customEventService.eventCalendarIsNextPressed;
    const isPreviousPressed = this.customEventService.eventCalendarIsPreviousPressed;
    this.automaticBookableDateProcessIsEnabled = true;

    LoggerService.log('[calendar][button state][next]', isNextPressed);
    LoggerService.log('[calendar][button state][previous]', isPreviousPressed);

    const currMonthDate = new Date(
      this.viewDate.getFullYear(),
      ([true, undefined].includes(isNextPressed)
        ? this.viewDate.getMonth() + 1
        : this.viewDate.getMonth() - 1
      ),
      1
    );

    if (currMonthDate >= minDate && currMonthDate < this.maxCalendarAvailabilityDate) {
      this.viewDate = currMonthDate;
      this.monthChanged = true;
      this.updateCalendarPrevState(this.viewDate);
      this.calendarLoaded = false;
    } else {
      this.calendarLoaded = true;
      this.automationBookingDatesLimitReached = true;

      // reset flags of next and previous calendar button
      LoggerService.log('[Reset flags of next and previous calendar button]');
      this.customEventService.eventCalendarIsNextPressed = undefined;
      this.customEventService.eventCalendarIsPreviousPressed = undefined;
    }
  }

  // Get slots when calendar day clicked
  onDateSelect({ day }: { day: CalendarMonthViewDay }): void | boolean {
    const formatedDate = this.utilService.dateToStr(day.date);

    if (day.isWeekend || day.isPast || day.date === this.appointmentState.date || !this.availableSlotsArray[formatedDate]) {
      LoggerService.warn('Selected date is not available due to may it is weekend or in past or disabled or already selected');
      return false;
    } else {
      this.toggleDateSelectedClass();
      this.appointmentState.day = day;
      this.appointmentState.date = day.date;
      this.freeAppointments = undefined;
      this.noAppointments = false;

      day.cssClass = 'cal-selected';
      day.badgeTotal = 0;

      if (this.oldSelectCalendarDate != null) {
        if (this.oldSelectCalendarDate.badgeTotal === 0) { this.oldSelectCalendarDate.cssClass = ''; }
      }

      if (!this.cartSupported) {
        this.cart = [];
        this.calendarSlotsCountList = [];
        if (this.oldSelectCalendarDate) {
          this.oldSelectCalendarDate.cssClass = '';
          this.oldSelectCalendarDate.badgeTotal = 0;
        }
      }

      this.oldSelectCalendarDate = day;
      this.showAvailableAppointments = true;
      this.showAppointmentSlotGhostElement = true;

      // trigger start event on first user interaction
      this.appointmentService.triggerCompactWidgetStartEvent();

      this.getFreeAppointments(formatedDate);
    }
  }

  // Setting up key names to store partner wise data into localstorage
  setLocalstorageNames(): void {
    LoggerService.log(this.partner);
    this.CART_LS_NAME = this.appointmentConstant.APPOINTMENT_CART_LS + this.partner.bookingName;
    this.USER_LS_NAME = this.appointmentConstant.APPOINTMENT_GLOBAL_USER_LS + this.partner.bookingName;
    this.CUSTOMFIELDS_LS_NAME = this.appointmentConstant.APPOINTMENT_CUSTOMFIELDS_LS_NAME + this.partner.bookingName;
    this.STATE_LS_NAME = this.appointmentConstant.APPOINTMENT_STATE_LS + this.partner.bookingName;
  }

  // On next prev month
  viewDateChanged(event: { viewDate: Date; isNext: boolean }): void {
    this.customEventService.eventCalendarIsNextPressed = event.isNext;
    this.customEventService.eventCalendarIsPreviousPressed = !event.isNext;

    this.freeAppointments = undefined;
    this.viewDate = event.viewDate;
    this.automaticBookableDateProcessIsEnabled = false;
    this.monthChanged = true;
    this.updateCalendarPrevState(event.viewDate);
    this.calendarLoaded = false;
    this.automationBookingDatesLimitReached = false;
    this.showAvailableAppointments = false;
  }

  private getFreeAppointments(date: string) {
    let freeAppointmentsSlots: AppointmentsSlotsDao[] = this.availableSlotsArray[date];
    this.appointmentService.resetSlotSettingsEvent.emit();
    this.freeAppointmentsCount = freeAppointmentsSlots.length;
    !freeAppointmentsSlots?.length && (this.noAppointments = true);
    this.showAppointmentSlotGhostElement = false;

    freeAppointmentsSlots = freeAppointmentsSlots.sort((a, b) => a.startHour - b.startHour);

    this.freeAppointments = {
      dayPeriod: undefined,
      appointments: freeAppointmentsSlots,
      showDayPeriodLabel: true,
      widgetGroup: undefined,
    };

    this.loadAppointmentCustomFields();
  }

  private toggleDateSelectedClass(): void {
    if (!this.cbcwWidgetWrapperRef.nativeElement.classList.contains('has-date-selected')) {
      this.cbcwWidgetWrapperRef.nativeElement.classList.add('has-date-selected');
    }
  }

  updateCart(eventData: {
    appointment: AppointmentsSlotsDao;
  }): void {
    this.addToCart(eventData.appointment);
  }

  private addToCart(appointment: AppointmentsSlotsDao): void {
    const appointmentStateDate = this.appointmentState.date;

    this.cartService.addToCart(
      appointment,
      this.appointmentState,
      [this.service],
      this.CART_LS_NAME,
      this.cart,
      false,
      true,
      [this.service.id],
      undefined,
      (cart: CartItem[]) => {
        this.cart = cart;
        this.totalCartPrice = this.cartService.totalCartPrice;

        // Reserve appointment if reservation feature is enabled
        this.reserverAppointment();

        this.calendarSlotsCountList = [
          { date: appointmentStateDate, slotsCount: 1 },
        ];

        this.calculateCoupon();

        this.customEventService.cartItemsChangeEvent.emit({
          cart: this.cart,
          type: WIDGET_CONSTANTS.APPOINTMENT,
        });

        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.COMPACT_WIDGET_BOOKING_STEP_AVAILABLE_APPOINTMENTS,
          this.prepareAnalyticsPayload()
        );

        this.currentView = this.appointmentConstant.PERSONAL_INFO;

        this.verifyOnlinePaymentStatus();
        this.resetDataBeforeLoadingSummaryPage();
      }
    );
  }

  private reserverAppointment(): void {
    if (Number(this.partner.is_appointment_reservation_enabled) === 1) {
      const appointment: AppointmentReservationBody = {
        appointment_service_id: this.cart[0].servicesIds[0],
        worker_id: this.cart[0].workerId,
        start_time: this.cart[0].startTime,
        end_time: this.cart[0].endTime
      };

      this.formService.reserveAppointments([appointment]).subscribe({
        next: reservation => {
          if (!reservation?.success) {
            LoggerService.warn('[Debug] Failed to reserve appointment');
          }
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.warn('[Debug] Failed to reserve appointment');
          LoggerService.error(error);
        }
      });
    }
  }

  removeAppointmentFromCart(cartItemId: string): void {
    this.cartService.removeFromCart(
      cartItemId,
      this.CART_LS_NAME,
      this.cart,
      (cart: CartItem[]) => {
        this.removeReservedAppointments(this.cart[0]);
        this.cart = cart;
        this.appointmentService.resetSlotSettingsEvent.emit();
        this.currentView = this.appointmentConstant.APPOINTMENT;
      }
    );
  }

  private removeReservedAppointments(reservedAppointment: CartItem): void {
    if (Number(this.partner.is_appointment_reservation_enabled) === 1) {
      const appointment: AppointmentReservationBody = {
        appointment_service_id: reservedAppointment.servicesIds[0],
        worker_id: reservedAppointment.workerId,
        start_time: reservedAppointment.startTime,
        end_time: reservedAppointment.endTime
      };

      this.formService.removeReservedAppointment([appointment]).subscribe({
        next: reservation => {
          if (!reservation?.success) {
            LoggerService.warn('[Debug] Failed to remove a reserved appointment');
          }
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.warn('[Debug] Failed to remove a reserved appointment');
          LoggerService.error(error);
        }
      });
    }
  }

  private loadAppointmentCustomFields(): void {
    this.isCustomFieldsLoaded = false;
    this.tempCustomFields = [];
    this.formService.getAppointmentCustomFields([this.service.id]).pipe(takeWhile(() => this.alive)).subscribe({
      next: (appointmentCustomFields) => {
        const trimmedArray: CustomFieldsDao[] = [];
        const values: number[] = [];

        if (!appointmentCustomFields) { appointmentCustomFields = []; }
        this.tempCustomFields = JSON.parse(JSON.stringify(appointmentCustomFields));

        this.tempCustomFields.forEach(customField => {
          if (values.indexOf(customField.id) === -1 && !customField.parent_custom_field_id) {
            trimmedArray.push(customField);

            // Add default option if it is select field
            if (['select', 'card-select', 'image-select'].includes(customField.type)) {
              if (
                customField.custom_field_options.length &&
                customField.custom_field_options[0]['id'] != null &&
                customField.type !== 'card-select' &&
                customField.type !== 'image-select' &&
                customField.type !== 'select'
              ) {
                const tempCustomFieldOptions = new CustomFieldOptions();
                tempCustomFieldOptions.id = null;
                customField.custom_field_options.unshift(tempCustomFieldOptions);
              }

              if (!this.customFieldValues[customField.id]) {
                if (customField.is_multiple_select === 1) {
                  let passedCustomFieldValue =
                    this.selectedJsonCustomFields && this.selectedJsonCustomFields[customField.id]
                      ? this.selectedJsonCustomFields[customField.id].split(',')
                      : [];
                  passedCustomFieldValue = passedCustomFieldValue.map(Number);

                  this.customFieldValues[customField.id] = {
                    value: passedCustomFieldValue,
                    type: customField.type,
                  };
                } else {
                  if (customField.type === 'select') {
                    if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customField.id]) {
                      this.customFieldValues[customField.id] = {
                        value: Number(this.selectedJsonCustomFields[customField.id]),
                        type: customField.type
                      };
                    } else if (this.customFieldValues[customField.id]) {
                      // Do nothing
                    } else {
                      this.customFieldValues[customField.id] = {
                        value: '',
                        type: customField.type,
                      };
                    }
                  } else if (['card-select', 'image-select'].includes(customField.type)) {
                    if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customField.id]) {
                      this.customFieldValues[customField.id] = {
                        value: [Number(this.selectedJsonCustomFields[customField.id])],
                        type: customField.type
                      };
                    } else if (this.customFieldValues[customField.id]) {
                      // Do nothing
                    } else {
                      this.customFieldValues[customField.id] = {
                        value: [],
                        type: customField.type,
                      };
                    }
                  }
                }
              }
            } else if (customField.type === 'number-select') {
              const optionIds = customField.custom_field_options.map(item => item.id);
              const optionValues: {
                custom_field_option_id: number;
                custom_field_option_value: any;
              }[] = customField.custom_field_options.map(item => {
                item.option_value = item.number_default;
                return {
                  custom_field_option_id: item.id,
                  custom_field_option_value: item.number_default,
                };
              }
              );
              this.customFieldValues[customField.id] = {
                value: optionIds,
                option_values: optionValues,
                type: customField.type,
              };
            } else if (customField.type === 'file') {
              this.customFieldValues[customField.id] = {
                type: 'file',
                file_ids: this.customFieldValues[customField.id]?.file_ids?.length > 0
                  ? this.customFieldValues[customField.id]?.file_ids
                  : [],
              };
            } else if (['text', 'textarea', 'radio', 'checkbox', 'date'].includes(customField.type)) {
              if (this.selectedJsonCustomFields && this.selectedJsonCustomFields[customField.id]) {
                this.customFieldValues[customField.id] = this.selectedJsonCustomFields[customField.id];
              } else {
                this.customFieldValues[customField.id] = this.customFieldValues[customField.id]
                  ? this.customFieldValues[customField.id]
                  : undefined;
              }
            }

            values.push(customField.id)
          } else {
            LoggerService.warn(`CALENSO_WIDGET: Custom field ${customField.id} is ignored!`);
          }
        });

        // Sort customfields by position field
        trimmedArray.sort((a, b) => a.position - b.position);

        this.appointmentCustomFields = trimmedArray;
        this.isCustomFieldsLoaded = true;

        LoggerService.log('customFieldValues', this.customFieldValues);
        LoggerService.log('appointmentCustomFields', this.appointmentCustomFields);
      },
      error: (error: HttpErrorResponse) => {
        this.isCustomFieldsLoaded = true;
        LoggerService.error(error);
      }
    });
  }

  switchLanguage(language: string): void {
    this.lang = language;
    this.translateService.use(language);
    this.globals.user_selected_language = language;
  }

  updateUserState(newUserState: UserState): void {
    this.userState = newUserState;
  }

  updateCustomerSelected(newCustomerSelected: object): void {
    this.customerSelected = newCustomerSelected;
  }

  private markFormGroupTouched(form: NgForm) {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  private verifyOnlinePaymentStatus(): void {
    this.partnerService.verifyOnlinePaymentStatus().subscribe({
      next: (result) => {
        if (result) {
          this.isServiceOnlinePaymentEnabled = Number(result.isServiceOnlinePaymentEnabled);
          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.stripeEnabled
          ) {
            this.isStripeEnabled = true;
            this.helperService.loadStripeJs();
          }

          if (result.isOnlinePaymentMandatory === 1) {
            this.isOnlinePaymentMandatory = true;
          }

          if (
            result.isBexioAppointmentServicePaymentEnabled === 1 &&
            result.bexioEnabled
          ) {
            this.isBexioEnabled = true;
          }

          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.saferpayEnabled
          ) {
            this.isSaferpayEnabled = true;
          }

          if (
            result.isServiceOnlinePaymentEnabled === 1 &&
            result.paypalEnabled
          ) {
            this.isPaypalEnabled = true;
            this.paypalSetting = new PayPalSettingsDbModel();
            this.paypalSetting.access_token = result.paypalAccessToken;
            this.paypalSetting.client_id = result.paypalClientId;
          }

          if (result.onlinePaymentShowOnSitePaymentType === 1) {
            this.isStoreEnabled = true;
          }
        }
        this.paymentType = undefined;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private resetDataBeforeLoadingSummaryPage(): void {
    this.isStripeEnabled = false;
    this.isOnlinePaymentMandatory = false;
    this.isBexioEnabled = false;
    this.isStoreEnabled = false;
    this.isPaypalEnabled = false;
    this.isSaferpayEnabled = false;
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.priceAfterCouponReduction = undefined;
    this.stripePaymentMethodId = undefined;
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;
  }

  private validatedPayment(): boolean {
    if (
      this.totalCartPrice === 0 ||
      this.totalCartPrice === -1 ||
      this.priceAfterCouponReduction <= 0
    ) {
      this.paymentType = 'store';
      return true;
    } else {
      if (
        !this.isBexioEnabled &&
        !this.isStripeEnabled &&
        !this.isPaypalEnabled &&
        !this.isSaferpayEnabled
      ) {
        this.paymentType = 'store';
        return true;
      } else {
        if (this.isOnlinePaymentMandatory) {
          if (
            (this.stripePaymentMethodId && this.paymentType === 'creditcard') ||
            (this.paymentType === 'invoice') ||
            (this.paypalOrderDetail && this.paymentType === 'paypal') ||
            (this.saferpayPaymentDetails && this.paymentType === 'saferpay')
          ) {
            return true;
          } else {
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        } else {
          if (this.paymentType === 'creditcard') {
            if (this.stripePaymentMethodId) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'invoice') {
            return true;
          } else if (this.paymentType === 'store') {
            return true;
          } else if (this.paymentType === 'saferpay') {
            if (this.saferpayPaymentDetails) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else if (this.paymentType === 'paypal') {
            if (this.paypalOrderDetail) {
              return true;
            } else {
              this.customEventService.paymentGatewayErrorEvent.emit(true);
              return false;
            }
          } else {
            this.customEventService.paymentGatewayErrorEvent.emit(true);
            return false;
          }
        }
      }
    }
  }

  book(): void {
    if (this.isValidBooking()) {
    }
  }

  private isValidBooking(): boolean {
    this.widgetUtilService.customerProfile = undefined;
    if (this.formService.imageUploaded === false) {
      LoggerService.warn('[Processing] Image is uploading...');
      this.markFormGroupTouched(this.appointmentForm);
      this.validatedPayment();
      return false;
    }

    if (!this.cart?.length) {
      LoggerService.warn('[Validation] Cart is empty');
      this.markFormGroupTouched(this.appointmentForm);
      this.validatedPayment();
      return false;
    }

    if (this.appointmentForm.invalid) {
      LoggerService.warn('[Validation] Form Validation failed');

      const errorSummary = [];
      Object.keys(this.appointmentForm.controls)
        .filter(controlKey => this.appointmentForm.controls[controlKey].invalid)
        .forEach(controlKey => errorSummary[controlKey] = this.appointmentForm.controls[controlKey].errors);
      LoggerService.warn(errorSummary);

      this.markFormGroupTouched(this.appointmentForm);
      this.validatedPayment();
      this.widgetUtilService.focusInvalidElements(this.elementRef);

      return false;
    }

    if (Number(this.partner.is_captcha_feature_enabled) === 1 && !this.userState.captcha_challenge) {
      LoggerService.warn('[Validation failed] Captcha is required');
      this.markFormGroupTouched(this.appointmentForm);
      this.validatedPayment();
      return false;
    }

    /*
    * Case1: Current view is personal info then check if cart price is > 0 and payment is enabled
    * then navigate to payment screen otherwise procced for booking
    * Case2: Current view is personal info and cart price is <= 0 then procced for booking
    * Case3: Current view is payment then check all the open amount must be paid and then procced for booking
    */
    if (this.currentView === this.appointmentConstant.PERSONAL_INFO) {
      if (this.totalCartPrice > 0 && this.isServiceOnlinePaymentEnabled === 1) {
        this.currentView = this.appointmentConstant.PAYMENT;
        this.googleAnalyticsService.emitBookingEvent(
          GTM_EVENTS.COMPACT_WIDGET_BOOKING_STEP_PAYMENT,
          this.prepareAnalyticsPayload()
        );
        return false;
      } else {
        this.doBooking();
        return true;
      }
    } else if (this.currentView === this.appointmentConstant.PAYMENT) {
      if (this.validatedPayment()) {
        this.doBooking();
        return true;
      } else {
        return false;
      }
    } else {
      LoggerService.warn('[Validation] Something went wrong');
    }
  }

  onChangePaymentType(event: string): void {
    this.paymentType = event;
    this.stripePaymentMethodId = null;
    this.paypalOrderDetail = undefined;
    this.saferpayPaymentDetails = undefined;

    // manage book and saferpay payment link visibility based on payment selection
    if (this.paymentType === 'saferpay') {
      this.showSaferpayButtonLink = true;
    } else {
      this.showSaferpayButtonLink = false;
    }
  }

  onCouponSuccessEvent(event: CouponDbModel): void {
    this.coupon = event;
    this.couponDisableConfirmButton = false;
    this.isInvalidCoupon = false;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;
    this.customEventService.couponChangeEvent.emit(this.finalCouponDiscount);

    // Case when payment type is saferpay and total cart price is 0 or -1 or price after coupon reduction is 0 or -1 then hide saferpay payment button and show actual book button
    if (
      this.paymentType === 'saferpay' &&
      (this.totalCartPrice === 0 || this.totalCartPrice === -1 || this.priceAfterCouponReduction <= 0)
    ) {
      this.showSaferpayButtonLink = false;
    }
  }

  onCouponFailedEvent(event: {
    disableBookingButton: boolean;
    invalidCoupon: boolean;
  }): void {
    this.coupon = undefined;
    this.finalCouponDiscount = 0;
    this.priceAfterCouponReduction = undefined;
    this.couponDisableConfirmButton = event.disableBookingButton;
    this.isInvalidCoupon = event.invalidCoupon;
    this.calculateCoupon();
    this.paypalOrderDetail = undefined;
    this.paymentType === 'saferpay' && (this.showSaferpayButtonLink = true);
  }

  calculateCoupon(): void {
    if (this.coupon && this.coupon.is_fixed_amount === 1) {
      this.finalCouponDiscount = Number(this.coupon.fixed_amount);
    }

    if (this.coupon && this.coupon.is_percentage === 1) {
      this.finalCouponDiscount = Number(((Number(this.totalCartPrice) / 100) * this.coupon.percentage).toFixed(2));
    }

    this.priceAfterCouponReduction = Number(this.totalCartPrice) - Number(this.finalCouponDiscount);
  }

  onStripeSuccessfulEvent(event: string) {
    this.stripePaymentMethodId = event;
  }

  onResetStripeTokenEvent(event: any) {
    this.stripePaymentMethodId = null;
  }

  onPaypalSuccessfulEvent(event: PayPalOrderDetailModel) {
    this.paypalOrderDetail = event;
    this.doBooking();
  }

  onResetPaypalEvent(event: any) {
    this.paypalOrderDetail = null;
  }

  payUsingSaferpay(): void {
    if (this.appointmentForm.valid && this.formService.imageUploaded === true) {
      this.showFullscreenOverlay = true;
      this.paymentService.initiateSaferpaySubject.next(true);
    } else {
      this.markFormGroupTouched(this.appointmentForm);
      this.validatedPayment();
    }
  }

  closeSaferpayOverlay(): void {
    this.showFullscreenOverlay = false;
    this.onResetSaferpayEvent('');
    this.paymentService.handleSaferpayBackgroundTriggerManually.next(true);
  }

  onSaferpaySuccessfulEvent(paymentDetails: SaferpayPaymentDetailsDbModel) {
    this.saferpayPaymentDetails = paymentDetails;
    this.showFullscreenOverlay = false;
    this.showSaferpayButtonLink = false;
    this.doBooking();
  }

  onResetSaferpayEvent(errorMessage: string): void {
    this.bookingErrorMessage = errorMessage;
    this.disableBookingButton = false;
    this.helperService.bookingLoader.emit(false);

    this.saferpayPaymentDetails = undefined;
    this.showFullscreenOverlay = false;
  }

  doBooking(): void {
    this.disableBookingButton = true;
    this.helperService.bookingLoader.emit(true);
    this.bookingErrorMessage = undefined;

    const bookingItems: AppointmentBookItem[] = [];
    const customFieldValues: {
      custom_field_id: number;
      type: string;
      value?: any;
      file_ids?: any;
      option_values?: {
        custom_field_option_id: number;
        custom_field_option_value: string;
      }[];
    }[] = [];
    const rawCustomFieldIds = this.tempCustomFields.map(i => i.id);

    let fieldIds = Object.keys(this.customFieldValues);
    if (rawCustomFieldIds?.length > 0) {
      fieldIds = fieldIds.filter(id => rawCustomFieldIds.indexOf(Number(id)) > -1);
    }

    for (let i = 0; i < fieldIds.length; i++) {
      if (this.customFieldValues[fieldIds[i]]?.type === 'select') {
        if (Array.isArray(this.customFieldValues[fieldIds[i]]?.value)) {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: this.customFieldValues[fieldIds[i]]?.value,
          });
        } else {
          customFieldValues.push({
            custom_field_id: Number(fieldIds[i]),
            type: 'select',
            value: [this.customFieldValues[fieldIds[i]]?.value],
          });
        }
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'card-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'card-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'image-select') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'image-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
        });
      } else if (
        this.customFieldValues[fieldIds[i]]?.type === 'number-select'
      ) {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'number-select',
          value: this.customFieldValues[fieldIds[i]]?.value,
          option_values: this.customFieldValues[fieldIds[i]]?.option_values,
        });
      } else if (this.customFieldValues[fieldIds[i]]?.type === 'file') {
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: 'file',
          file_ids: Object.assign(
            [],
            this.customFieldValues[fieldIds[i]]?.file_ids
          ),
        });
      } else {
        let tempCustomFieldsDao: CustomFieldsDao;
        if (this.tempCustomFields?.length > 0) {
          tempCustomFieldsDao = this.tempCustomFields.find((item) => {
            return Number(item.id) === Number(fieldIds[i]);
          });
        }
        customFieldValues.push({
          custom_field_id: Number(fieldIds[i]),
          type: tempCustomFieldsDao ? tempCustomFieldsDao?.type : '',
          value: this.customFieldValues[fieldIds[i]]?.toString(),
        });
      }
    }

    LoggerService.log('Final custom field values', customFieldValues);

    for (const i in this.cart) {
      if (this.cart.hasOwnProperty(i)) {
        const bookingData: AppointmentBookItem = {
          store_id: this.cart[i].storeId,
          store_name: this.cart[i].storeName,
          partnerId: this.partner['id'],
          services: this.cart[i]['servicesIds'].join(),
          referenceServices: this.cart[i]['referenceServices'],
          worker_id: this.cart[i]['workerId'],
          worker_email: this.cart[i].workerEmail,
          resource_name: this.cart[i].workerName,
          start: this.cart[i]['startTime'],
          end: this.cart[i]['endTime'],
          title: this.userState.title,
          prename: this.userState.forename,
          gender: this.userState.gender,
          lastname: this.userState.lastName,
          email: this.userState.eMail,
          phone: this.userState.phone,
          mobile: this.userState.mobile,
          comment: this.userState.comment,
          customer_id: this.userState.customerId,
          customer_uuid: this.userState.customerUuid,
          country: this.userState.country_id,
          company_name: (this.userState?.company || undefined),
          internal_comment: (this.userState?.internal_comment || undefined),
          captcha: (this.userState?.captcha_challenge || undefined),
          token: (this.appointmentState?.captcha?.uuid || undefined),
          customer_notification_preference: this.userState.customer_notification_preference,
          meeting_type_id: this.userState.meeting_type_id,
          customFieldValues: customFieldValues,
          user_browser_language: this.globals.user_browser_language,
          user_selected_language: this.globals.user_selected_language,
          coupon_code: this.coupon && !this.isInvalidCoupon ? this.coupon.code : null,
          payment_type: this.paymentType,
          booker_worker_id: this.bookerWorkerId,
          utm_source: ((this.utmSource && this.utmSource !== 'null') ? this.utmSource : undefined),
          utm_content: ((this.utmContent && this.utmContent !== 'null') ? this.utmContent : undefined),
          utm_medium: ((this.utmMedium && this.utmMedium !== 'null') ? this.utmMedium : undefined),
          utm_campaign: ((this.utmCampaign && this.utmCampaign !== 'null') ? this.utmCampaign : undefined),
          utm_term: ((this.utmTerm && this.utmTerm !== 'null') ? this.utmTerm : undefined),
          is_internal: this.globals?.isInternal ? true : false,
          ...(this.partner?.show_address_on_widget !== 0 ? {
            street: this.userState.street,
            zip: this.userState.zip,
            city: this.userState.city
          } : {}),
        };

        if (this.paymentType === 'paypal') {
          bookingData.paypal_order_id = this.paypalOrderDetail.paypal_order_id;
          bookingData.paypal_payer_id = this.paypalOrderDetail.paypal_payer_id;
          bookingData.paypal_status = this.paypalOrderDetail.paypal_status;
          bookingData.paypal_raw_data = this.paypalOrderDetail.paypal_raw_data;
          bookingData.paypal_capture_id =
            this.paypalOrderDetail.paypal_capture_id;
        } else if (this.paymentType === 'saferpay') {
          bookingData.saferpay_transaction_id = this.saferpayPaymentDetails.transaction_id;
          bookingData.saferpay_payment_method = this.saferpayPaymentDetails.payment_method;
        } else if (this.paymentType === 'creditcard') {
          bookingData.stripe_card_token = this.stripePaymentMethodId ? this.stripePaymentMethodId : null;
        }

        bookingItems.push(bookingData);
      }
    }

    if (this.isBookingDisabled) {
      LoggerService.error('Booking is disabled');
      LoggerService.table(bookingItems);
      return;
    }

    this.formService
      .bookAppointments(bookingItems)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (result: {
          success?: boolean;
          errors?: { message: string }[];
          appointments?: any[];
        }) => {
          if (result.errors && result.errors[0] && result.errors[0].message) {
            this.bookingErrorMessage = result.errors[0].message;
            return;
          }

          if (result.success) {
            LoggerService.log('Appointment booking done before send postmessage');
            if (result?.appointments?.length > 0) {
              for (const item of result?.appointments) {
                this.successFullBookings.push({
                  landingPageLink: environment?.calioDashboardBaseUrl + '/appointments/' + item?.uuid,
                  bookingUUID: item?.uuid,
                  selectedStore: item?.worker?.store,
                });
              }
            }

            // send booking success to event to parent window
            this.googleAnalyticsService.emitEventToParent(WINDOW_LISTENER_CONSTANTS.APPOINTMENT_BOOKING_DONE, result?.appointments);
            this.googleAnalyticsService.emitBookingEvent(this.gtmEvents.APPOINTMENT_BOOKING_STEP_SUCCESS);

            this.utilService.updateLocalStorage(this.CUSTOMFIELDS_LS_NAME, this.customFieldValues);
            this.userState.meeting_type_id = undefined;
            this.userState.captcha_challenge = undefined;
            this.utilService.updateLocalStorage(this.USER_LS_NAME, Object.assign({}, this.userState));

            this.bookingSuccessEvent.emit();
            this.finalPage = true;

            // clear cart from ls and object
            this.localStorageService.removeItem(this.STATE_LS_NAME, () => { });
            this.localStorageService.removeItem(this.CART_LS_NAME, () => { });

            this.appointmentState = {
              store: null,
              services: [],
              relatedServices: [],
              worker: null,
              date: null,
              termins: null,
              day: null,
              leadGeneratorPostcode: null,
            };

            this.cart = [];
            this.calendarSlotsCountList = [];
          } else {
            this.finalPage = false;
          }
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;
          this.booked = true;
          this.disableBookingButton = false;
          this.isInvalidCoupon = false;
          this.helperService.bookingLoader.emit(false);
          this.appointmentService.gtmStartTriggered = false;

          if (
            this.partner.is_redirect_after_booking_enabled &&
            this.partner.successful_booking_redirect_url &&
            !this.globals.isInternal
          ) {
            window.open(this.partner.successful_booking_redirect_url, '_blank');
          }

          this.localStorageService.setItem(LOCAL_STORAGE_CONSTANTS.LAST_PAYMENT_METHOD, this.paymentType, () => {
            LoggerService.log('LAST_PAYMENT_METHOD saved', this.paymentType);
          });
        },
        error: (error) => {
          LoggerService.error(error);
          if (error.status === 500) {
            this.translateService
              .get('common.noDateError')
              .pipe(takeWhile(() => this.alive))
              .subscribe({
                next: (translation) => {
                  this.finalPageError = translation;
                  this.finalPage = false;
                  this.booked = true;
                  this.disableBookingButton = false;
                  this.helperService.bookingLoader.emit(false);
                }
              });
          } else {
            this.finalPageError = error.message;
            this.finalPage = false;
            this.booked = true;
            this.disableBookingButton = false;
            this.helperService.bookingLoader.emit(false);
          }
          this.googleAnalyticsService.emitBookingEvent(this.gtmEvents.APPOINTMENT_BOOKING_STEP_FAILED);
          this.priceAfterCouponReduction = undefined;
          this.stripePaymentMethodId = undefined;
          this.paypalOrderDetail = undefined;
          this.isInvalidCoupon = false;
        }
      });
  }

  hideBookingAgainButton(): boolean {
    if (this.finalPage) {
      if (this.partner.hide_book_additional_appointment_button === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  bookAppointmentAgain(): void {
    this.appointmentService.gtmStartTriggered = false;
    this.successFullBookings = [];
    this.initFormState();
    this.booked = false;
    this.currentView = this.appointmentConstant.APPOINTMENT;
    this.finalPage = false;
    this.finalPageError = undefined;
    this.backButtonDisabled = false;
    this.showAvailableAppointments = false;
  }

  goBack(screen = ''): void {
    if (screen === this.appointmentConstant.APPOINTMENT) {
      this.appointmentState.date = null;
      this.showAvailableAppointments = false;
    } else {
      if (this.currentView === this.appointmentConstant.PERSONAL_INFO) {
        this.showAppointmentSlotGhostElement = true;
        this.freeAppointmentsCount = 0;
        this.monthChanged = false;
        this.currentView = this.appointmentConstant.APPOINTMENT;
        this.backButtonDisabled = false;
      } else if (this.currentView === this.appointmentConstant.PAYMENT) {
        this.currentView = this.appointmentConstant.PERSONAL_INFO;
      }
    }
  }

  prepareAnalyticsPayload(): AppointmentAnalyticsDao {
    const payload = new AppointmentAnalyticsDao();
    const store: { [key: string]: string | number } = {};
    const worker: { [key: string]: string | number } = {};
    const servicesName = [];

    // get store details if present
    store.id = this.store?.id;
    store.name = this.store?.name || undefined;

    // get worker details if present
    worker.id = this.worker.id;
    worker.email = this.worker?.email;
    worker.name = this.worker?.resource_name;

    // prepare service name string
    servicesName.push(this.service.name);

    // prepare final payload object
    if (this.appointmentState?.services) {
      payload.appointment_service_id = this.appointmentState.services.join(',');
    }

    if (servicesName.length) {
      payload.appointment_service_name = servicesName.join(',');
    }

    if (worker?.id) {
      payload.worker_id = worker.id;
      payload.resource_name = worker?.name;
      payload.worker_email = worker?.email;
    }

    if (store?.id) {
      payload.store_id = store.id;
      payload.store_name = store?.name;
    }

    LoggerService.log('[Analytics payload]', payload);
    return payload;
  }
}
