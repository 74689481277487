import { Injectable } from '@angular/core';
import { AppointmentServiceDao } from '../db-models/appointment-service-dao';
import { AppointmentsSlotsDao } from '../db-models/free-appointment-dao';
import { CartItem, CartItemReferenceSerice } from '../models/cart.model';
import { AppointmentState } from '../models/state.model';
import { AppointmentService } from './appointment.service';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentCartService {
  totalCartDuration: number = 0;
  totalCartPrice: number = 0;

  constructor(
    private appointmentService: AppointmentService,
    private localStorageService: LocalStorageService,
    private utilService: UtilService
  ) {}

  addToCart(
    appointment: AppointmentsSlotsDao,
    appointmentState: AppointmentState,
    appoinrmentServicesAvailable: AppointmentServiceDao[],
    cartLsName: string,
    cart: CartItem[],
    overwrite: boolean,
    ignoreRelatedServiceId: boolean,
    services: number[],
    relatedAppointmentCardId: number,
    callback: any
  ): void {
    const cartItem: CartItem = this.createCartItem(
      appointment,
      appoinrmentServicesAvailable,
      ignoreRelatedServiceId,
      appointmentState.store,
      appointmentState.date,
      services,
      relatedAppointmentCardId
    );
    this.localStorageService.getItem(cartLsName, (lsCart: any) => {

      cart = this.initCartValue(lsCart, cart);

      if (overwrite) {
        cart.push(cartItem);
      } else {
        cart = [];
        cart.push(cartItem);
      }

      this.utilService.updateLocalStorage(cartLsName, cart);
      this.recalculateCart(cart);
      callback(cart);
    });
  }

  removeFromCart(
    cartItemId: string,
    cartLsName: string,
    cart: CartItem[],
    callback: any
  ): void {
    this.localStorageService.getItem(cartLsName, (lsCart: any) => {
      cart = this.initCartValue(lsCart, cart);
      cart = this.deleteItemFromCart(cart, cartItemId);
      this.utilService.updateLocalStorage(cartLsName, cart);
      this.recalculateCart(cart);
      callback(cart);
    });
  }

  recalculateCart(cart: CartItem[]): void {
    if (cart) {
      this.totalCartDuration = 0;
      this.totalCartPrice = 0;
      cart.forEach((c) => {
        this.totalCartDuration = this.totalCartDuration + c.total.duration;
        if (c.total.price > 0) {
          if (this.totalCartPrice === -1) {
            this.totalCartPrice = c.total.price;
          } else {
            this.totalCartPrice = this.totalCartPrice + c.total.price;
          }
        } else if (c.total.price === -1) {
          if (this.totalCartPrice > 0) {
          } else {
            this.totalCartPrice = -1;
          }
        }
      });
    }
  }

  initCartValue(lsCart: CartItem[], cart: CartItem[]): CartItem[] {
    if (lsCart !== null) {
      cart = lsCart;
    } else {
      cart = [];
    }
    return cart;
  }

  deleteItemFromCart(cart: CartItem[], cartItemId: string): CartItem[] {
    // Delete from cart if not empty
    for (let i = cart.length - 1; i >= 0; i--) {
      if (cart[i].cartItemId === cartItemId) {
        cart.splice(i, 1);
      }
    }

    return cart;
  }

  createCartItem(
    appointment: AppointmentsSlotsDao,
    appoinrmentServicesAvailable: AppointmentServiceDao[],
    ignoreRelatedServiceId: boolean,
    store: number,
    date: Date,
    services: number[],
    relatedAppointmentCardId: number
  ): CartItem {
    const cartItem: CartItem = {
      storeId: store,
      storeName: appointment?.store?.name,
      store: appointment?.store,
      date: date,
      location: appointment?.location,
      startTime: appointment.start,
      shortStart: Number(appointment.short_start),
      endTime: appointment.end,
      workerId: appointment?.worker?.id,
      servicesIds: services,
      cartItemId: this.appointmentService.createCartItemId(
        appointment?.worker?.id,
        date,
        Number(appointment.short_start)
      ),
      workerName: appointment?.worker?.resource_name,
      workerEmail: appointment?.worker?.email,
      total: this.appointmentService.getServicesByIds(
        { serviceIds: services, returnKey: 'total' },
        appoinrmentServicesAvailable
      ),
      relatedServiceIds: [],
      relatedServices: [],
      relatedAppointmentCardId,
      referenceServices: [],
      parentServiceId: appointment.parentServiceId
    };

    const referenceServices = Array<CartItemReferenceSerice>();
    if (cartItem?.servicesIds?.length) {
      const foundedService = appoinrmentServicesAvailable.find(item => cartItem?.servicesIds?.indexOf(item?.id) > -1);
      if (foundedService) {
        referenceServices.push({
          serviceId: foundedService.id,
          serviceName: foundedService.name,
          serviceCategoryId: foundedService.appointment_service_category_id
        });
      }
    }

    cartItem.referenceServices = referenceServices;

    if (!ignoreRelatedServiceId) {
      for (const temp of appoinrmentServicesAvailable) {
        if (
          temp?.related_appointment_service_id &&
          services?.indexOf(temp.id) > -1
        ) {
          cartItem.relatedServiceIds.push(temp?.related_appointment_service_id);
        }
      }

      for (const temp of cartItem?.relatedServiceIds) {
        for (const temp1 of appoinrmentServicesAvailable) {
          if (temp1.id === temp) {
            cartItem.relatedServices.push(temp1);
          }
        }
      }
    }
    return cartItem;
  }
}
